import React from 'react'
import { css } from 'theme-ui'
import { Link } from 'gatsby'

export default function () {
  return (
    <div css={css({ textAlign: 'center', my: '10vh' })}>
      <pre>
        <pre>`ヽ｀、ヽ｀｀、ヽ｀ヽ｀、、ヽ ヽヽ</pre>
        <pre>｀ヽ、ヽヽ｀ヽ、｀ヽ 、ヽ｀🌤｀ヽ</pre>
        <pre>｀ヽ｀、ヽ｀｀、ヽ 、｀｀、｀、ヽ</pre>
        <pre>｀ 、｀ ヽ｀ヽ、ヽ ｀、ヽ｀｀、、</pre>
        <pre>｀ヽ｀、、ヽ ｀ヽ、ヽ｀ヽ、｀、｀</pre>
        <pre>｀、ヽ🚶🏻‍♂️｀ヽ｀、｀ヽ、｀｀ヽ ｀｀</pre>
      </pre>
      <p>
        <br />
      </p>
      <p>人生是一段旅程。</p>
      <p>属于我们每个人，唯一的旅程。</p>
      <p>终点是确定的。</p>
      <p>但是，终点又是不确定的。</p>
      <p>你总是以为，明天，太阳会照常升起。</p>
      <p>这成为了你的"永恒"。</p>
      <p>你能感觉到这"永恒"，</p>
      <p>你也越来越强烈地从它留下的蛛丝马迹中感觉到它的逝去。</p>
      <p>你得以继续前行。</p>
      <p>你保持前进。</p>
      <p>你遇到一些人，你也和一些人走散。</p>
      <p>这让你开心或者难过。</p>
      <p>你会经历一些事。</p>
      <p>这让你开心或者难过。</p>
      <p>但是，</p>
      <p>你只是保持前进。</p>
      <p>因为你知道，这，终会过去。</p>
      <p>你只是保持前进。</p>
      <p>因为你知道，这，终不会过去。</p>
      <p>你载着它负重前行。</p>
      <p>你终将到达终点。</p>
      <p>现在，你只是保持前进。</p>
      <br />
      <p>
        <Link to="/">🔙</Link>
      </p>
    </div>
  )
}
